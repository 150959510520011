import React from 'react'
import logo from "../assets/3d-person-shrugging-m-1983.jpeg"

const NoPage = () => {
  return (
    <div class="inset-0 absolute bg-[#A5091A] grid place-items-center">
        <div class="bg-white p-4 rounded-lg max-w-screen-sm w-96 thanks-animation">
            <div class="text-center">
                <img alt="Logo" loading="lazy"  decoding="async" data-nimg="1" class="mx-auto max-w-full w-[19.125rem] mb-4" src= {logo} />
                <h1 class="text-3xl font-semibold text-slate-600  mb-1">Aradığınız Sayfa Bulunamadı.</h1>
                </div>
                <a class="bg-slate-600 ring-default text-white rounded-md block w-full py-2 mt-4 text-center" href="/">Anasayfaya Dön</a></div></div>
  )
}

export default NoPage