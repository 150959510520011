import React from 'react'
import Banner from "./Banner"
import Bottom from './Bottom'

const Main = () => {
  return (
    <section className="w-full max-w-[75.6rem] my-0 mx-auto">
      <main>
        <Banner/>
        <Bottom/>
      </main>
      </section>
  )
}
/* kontrol edildi */
export default Main;