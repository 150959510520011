import React from 'react'
import logo from "../assets/logo.png"
import { useNavigate } from 'react-router-dom';


export default function Thanks() {

  const navigate = useNavigate()
  setTimeout(() => {
    navigate("/")
  }, 3000);

  return (
    <div class="inset-0 absolute bg-[#14365F] grid place-items-center">
      <div class="bg-white p-4 rounded-lg max-w-screen-sm w-96 thanks-animation">
        <div class="text-center">
          <img alt="Logo" loading="lazy" decoding="async" data-nimg="1" class="mx-auto max-w-full w-[19.125rem] mb-4" src={logo} />
          <h1 class="text-3xl font-semibold text-[#14365F]  mb-1">Başvurunuz alınmıştır.</h1>
          <p>En kısa sürede sizinle iletişime geçilecektir.</p></div>
        <a className="bg-[#14365F] ring-default text-white rounded-md block w-full py-2 mt-4 text-center" href="/">Anasayfaya Dön</a></div></div>
  )
}

/* style="color: transparent;" çıkartıldı */