import React from 'react';
import Full from './components/Full';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Thanks from './components/Thanks';
import NoPage from './components/NoPage';
//import NoPage from './components/NoPage';

const App = () => {
  
  return (
    
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Full />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="*" element = {<NoPage />} />
        
          </Routes>
      </BrowserRouter>
    
  );
  
};

export default App;
