import React from 'react'
import Main from './Main'
import Header from './Header'


const Full = () => {
  return (
    <>
      <Header/>
      <Main/></>
  )
}
/*transition-[all_0.3s] transition-all çıkartıldı*/
export default Full
