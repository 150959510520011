import React, { useState } from 'react'
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phone: '',
    email: ''
  });


  const [errors, setErrors] = useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
    form: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [sendButton, setSendButton] = useState("Gönder")


  const navigate = useNavigate()
  const handleSendButton = () => {

    setTimeout(() => {
      setSendButton("Gönderiliyor.")

      setTimeout(() => {
        setSendButton("Gönderiliyor..")

        setTimeout(() => {
          setSendButton("Gönderiliyor...")
        }, 700);
      }, 600);
    }, 500);

  }

  const handlePhoneNumber = (event) => {
    const value = (event.target.value
      .replace(/\D/g, "")
      .replace(/^0+/, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
    );
    event.target.value = !value[2]
      ? value[1]
      : "(" + value[1] + ") " + value[2]
      + (value[3] ? " " + value[3] : "")
      + (value[4] ? " " + value[4] : "");

    setFormData({ ...formData, phone: event.target.value });
  };

  const parsePhoneNumber = (value) => value.replace(/[^0-9]/g, "");

  const validateForm = () => {
    const newErrors = {
      name: '',
      surname: '',
      phone: '',
      email: '',
      form: ''
    };

    if (formData.name.trim() === "") {
      newErrors.name = "Lütfen isminizi giriniz.";
    } else if (formData.name.trim().length < 2) {
      newErrors.name = "İsminiz en az 2 karakterden oluşmalıdır.";
    } else if (formData.name.trim().length > 30) {
      newErrors.name = "İsminiz en fazla 30 karakterden oluşmalıdır.";
    } else if (formData.name.trim().match(/[^a-zA-ZığüşöçİĞÜŞÖÇ ]/)) {
      newErrors.name = "İsminiz sadece harflerden oluşmalıdır.";
    }

    if (formData.surname.trim() === "") {
      newErrors.surname = "Lütfen soyisminizi giriniz.";
    } else if (formData.surname.trim().length < 2) {
      newErrors.surname = "Soyisminiz en az 2 karakterden oluşmalıdır.";
    } else if (formData.surname.trim().length > 30) {
      newErrors.surname = "Soyisminiz en fazla 30 karakterden oluşmalıdır.";
    } else if (formData.surname.trim().match(/[^a-zA-ZığüşöçİĞÜŞÖÇ]/)) {
      newErrors.surname = "Soyisminiz sadece harflerden oluşmalıdır.";
    }

    if (formData.phone === "") {
      newErrors.phone = "Lütfen telefon numaranızı giriniz.";
    } else if (parsePhoneNumber(formData.phone).startsWith("0")) {
      newErrors.phone = "Telefon numaranız 0 ile başlamamalıdır.";
    } else if (parsePhoneNumber(formData.phone).length !== 10) {
      newErrors.phone = "Telefon numaranız 10 haneli olmalıdır.";
    }

    if (formData.email === "") {
      newErrors.email = "Lütfen e-posta adresinizi giriniz.";
    } else if (!formData.email.includes("@")) {
      newErrors.email = "Eposta adresiniz \"@\" içermelidir.";
    } else if (!formData.email.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)) {
      newErrors.email = "Eposta adresiniz formatına uygun olmalıdır.";
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const data = new FormData();
      data.append("name", formData.name.trim());
      data.append("surname", formData.surname.trim());
      data.append("phone", parsePhoneNumber(formData.phone));
      data.append("email", formData.email.trim());
      data.append("source", "kalealarm");
      handleSendButton()

      try {
        fetch("https://api.useasa.com/api/lead/Create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Object.fromEntries(data))
        }).then(() => {
          setFormData({
            name: '',
            surname: '',
            phone: '',
            email: ''
          })
          setErrors({
            form: '',
            name: '',
            surname: '',
            phone: '',
            email: ''
          })
        }).then(() => {
          setSendButton("Gönderildi.")
          navigate("/thanks")
        })
      } catch (error) {
        console.error('Error:', errors)
        setErrors({ ...errors, form: "Form gönderilirken bir hata oluştu. Lütfen tekrar deneyiniz." })
        setSendButton("Gönderilemedi.")
      } finally {
        setIsSubmitted(true)
      }
    }
  };


  return (
    /*form container */
    <div className="sm:translate-y-6 sm:w-full sm:m-0 md:my-0 md:mx-8 md:w-[calc(100%-4rem)] flex ml-auto mr-auto justify-end items-center">
      <form noValidate className="sm:py-8 sm:px-8 gap-2 dark:bg-[#1A1A1A] dark:shadow-3xl sm:p-1 sm:gap-2 md:m-0 md:w-full flex flex-col p-8 bg-white shadow-3xl rounded-2xl" onSubmit={handleSubmit}>
        <h2 className="md:text-[1rem] md:font-[700] md:m-0 text-[#C50002] text-[2rem] font-bold m-0">ÜCRETSİZ KEŞİF!</h2>
        <label htmlFor="name">AD</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Ad"
          value={formData.name}
          className={classNames("dark:border-solid dark:border  p-[0.8rem] mb-4 border border-1 border-solid  bg-none font-customFont2 text-[1rem]  min-w-0 rounded-lg placeholder:text-[#B3B3B3] dark:bg-[#1A1A1A]",
            {
              'border-red-500': errors.name,
              'border-gray-500': !errors.name,
            })} onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <small className="text-[#C50002] text-[0.8rem] -mt-5 ml-1 mb-4">{errors.name}</small>

        <label htmlFor="surname">SOYAD</label>
        <input
          type="text"
          id="surname"
          name="surname"
          placeholder="Soyad"
          value={formData.surname}
          className={classNames("dark:border-solid dark:border  p-[0.8rem] mb-4 border border-1 border-solid  bg-none font-customFont2 text-[1rem]  min-w-0 rounded-lg placeholder:text-[#B3B3B3] dark:bg-[#1A1A1A]",
            {
              'border-red-500': errors.surname,
              'border-gray-500': !errors.surname,
            })} onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
        />
        <small className="text-[#C50002] text-[0.8rem] -mt-5 ml-1 mb-4">{errors.surname}</small>

        <label htmlFor="phone">TELEFON</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="(___) ___ __ __"
          value={formData.phone}
          className={classNames("dark:border-solid dark:border  p-[0.8rem] mb-4 border border-1 border-solid  bg-none font-customFont2 text-[1rem]  min-w-0 rounded-lg placeholder:text-[#B3B3B3] dark:bg-[#1A1A1A]",
            {
              'border-red-500': errors.phone,
              'border-gray-500': !errors.phone,
            })}
          onChange={handlePhoneNumber}
        />
        <small className="text-[#C50002] text-[0.8rem] -mt-5 ml-1 mb-4">{errors.phone}</small>

        <label htmlFor="email">EPOSTA</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Eposta"
          value={formData.email}
          className={classNames("dark:border-solid dark:border  p-[0.8rem] mb-4 border border-1 border-solid  bg-none font-customFont2 text-[1rem] min-w-0  rounded-lg placeholder:text-[#B3B3B3] dark:bg-[#1A1A1A]",
            {
              'border-red-500': errors.email,
              'border-gray-500': !errors.email,
            })} onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <small className="text-[#C50002] text-[0.8rem] -mt-5 ml-1 mb-4">{errors.email}</small>

        <button disabled={isSubmitted} type="submit" className="mt-4 border-none rounded-[1.5rem] text-white bg-[#C50002] py-3 px-6 font-semibold text-[1rem] min-w-0 cursor-pointer">{sendButton}</button>
        <small className="text-[#C50002] text-[0.8rem] mt-5 ml-1 mb-4">{errors.form}</small>

      </form>

    </div>
  )
}

export default Form;

/* classnameden bitmez dark:border-[#B3B3B3] */