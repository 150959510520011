import React from 'react'
import banner1 from "../assets/banner1.png"

export default function Banner(){
  return (
    <div className="dark:bg-[#00000088] dark:bg-blend-overlay shadow-3xl bg-cover h-[34.3rem] flex items-center xs:p-4 p-12 my-0 md:mx-4 mx-8 rounded-[3.9rem] lg:bg-center lg:bg-[#FFFFFF88] lg:bg-blend-overlay lg:rounded-[2rem]" style={{ backgroundImage: `url(${banner1})` }}>
          <div className="max-w-[32rem] flex flex-col gap-6 text-[1.25rem]">
            <h1 className="xs:text-[1.5rem] sm:text-[1.8rem] md:text-[2.5rem] text-[3.5rem] leading-[1.33]">
              Kale Alarm ile 
              <br></br>Tüm Sevdikleriniz <span className="text-[#C50002]">Güvende</span>
              </h1>
            <ul className="list-none">
              <li className="relative ml-6 xs:ml-2 xs:text-lg"><span className="absolute top-[-0.3rem] left-[-1.2rem] text-[#C50002] font-bold text-[1.75rem]">•</span>70 Yıllık Güven</li>
              <li className="relative ml-6 xs:ml-2 xs:text-lg"><span className="absolute top-[-0.3rem] left-[-1.2rem] text-[#C50002] font-bold text-[1.75rem]">•</span>TL Üzerinden Fiyatlandırma</li>
              <li className="relative ml-6 xs:ml-2 xs:text-lg"><span className="absolute top-[-0.3rem] left-[-1.2rem] text-[#C50002] font-bold text-[1.75rem]">•</span>7/24 Alarm İzleme Hizmeti</li>
            </ul>
          </div>
        </div>
  )
}
/* tailwind kontrolü yapıldı */ 