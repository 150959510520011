import React from 'react'
import diaphone from "../assets/diaphone.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import '../index.css';
export default function WeDos() {


  return (

    /* what we do, content ve left */
    <div className='sm:mt-[-2.5rem] sm:w-full sm:m-0 md:w-[calc(100%-4rem)] md:m-8 m-8 mt-32 w-[calc(100%-30rem)]'>
      <div className="flex gap-8 justify-between xl:flex xl:flex-col 2xl:gap-0">
        <div className="max-w-[18.75rem] xl:max-w-full">

          <Swiper style={{
            "--swiper-pagination-color": "#C50002",
            "--swiper-pagination-bullet-inactive-color": "#999999",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "12px",
            "--swiper-pagination-bullet-horizontal-gap": "6px"
          }} pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide className='p-[0.3rem] mb-8'><h3 className="text-[#C50002] mb-2 text-[1rem]">NE YAPIYORUZ?</h3>
              <h2 className="text-[2.1rem] font-bold mb-6">Biz Sizin Yerinize Koruyoruz.</h2> <p className=" text-[0.9rem] leading-normal mb-6">Kale Alarm, en yeni teknoloji ile ev ve iş yerinizi korur. Olası tehlikede anında bildirim alırsınız.
                Güvende hissedin.</p></SwiperSlide>
            <SwiperSlide className='p-[0.3rem] mb-8'><h3 className="text-[#C50002] mb-2 text-[1rem]">NE YAPIYORUZ?</h3>
              <h2 className="text-[2.1rem] font-bold mb-6">Biz Güven Veriyoruz.</h2> <p className=" text-[0.9rem] leading-normal mb-6">Kale Alarm, en yeni teknoloji ile ev ve iş yerinizi korur. Olası tehlikede anında bildirim alırsınız.
                Güvende hissedin.</p></SwiperSlide>
            <SwiperSlide className='p-[0.3rem] mb-8'><h3 className="text-[#C50002] mb-2 text-[1rem]">NE YAPIYORUZ?</h3>
              <h2 className="text-[2.1rem] font-bold mb-6">Biz Kaliteli Hizmet Veriyoruz</h2><p className=" text-[0.9rem] leading-normal mb-6">Kale Alarm, en yeni teknoloji ile ev ve iş yerinizi korur. Olası tehlikede anında bildirim alırsınız.
                Güvende hissedin.</p></SwiperSlide>
          </Swiper>
        </div>
        <div className="2xl:grid 2xl:place-items-center">
          <img className='max-w-full min-w-32' loading="lazy" src={diaphone} alt="Kale Alarm" aria-label="Kale Alarm" />
        </div></div></div>
  )
}

/* <h3 className="text-[#C50002] mb-2 text-[1rem]">NE YAPIYORUZ?</h3>
    <h2 className="text-[2.1rem] font-bold mb-6">Biz Sizin Yerinize Koruyoruz.</h2> <p className =" text-[0.9rem] leading-normal mb-6">Kale Alarm, en yeni teknoloji ile ev ve iş yerinizi korur. Olası tehlikede anında bildirim alırsınız.
      Güvende hissedin.</p> */