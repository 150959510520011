import React from 'react'
import Form from "./Form"
import WeDos from "./WeDos"

const Bottom = () => {
  return (
    /* content aligner */
    <div className="md:-translate-y-16 md:flex-col flex my-0 md:mx-0 mx-8 flex-row-reverse items-start -translate-y-24">
        <Form/>
        <WeDos/>
        </div>
  )
}
/* kontrol edildi */
export default Bottom
