import React from 'react'
import logo from "../assets/logo.png"
import darklogo from "../assets/logo-dark.png"


export default function Header (){
    return (
    
    <header className="py-8 px-0 grid place-items-center my-0 mx-8">
    <div className="md:justify-center w-full max-w-[75.6rem] py-0 px-8 flex justify-between items-center">
      <img src= {logo} className="sm:w-[unset] sm:max-w-full sm:-mb-4 w-[19.125rem] dark:hidden" alt="Kale Kilit" aria-label="Kale Kilit"/>
      <img src={darklogo} className="sm:w-[unset] sm:max-w-full sm:-mb-4 w-[19.125rem] hidden dark:inline" alt="Kale Kilit" aria-label="Kale Kilit"/>
      <nav className="md:hidden flex justify-between items-center gap-4">
        <a href="https://www.kalekilit.com.tr/tr/iletisim" target="_blank" rel="noopener noreferrer" className="dark:text-white no-underline text-black">İletişim</a>
        <button className="border-none rounded-[1.5rem] text-white bg-[#C50002] py-3 px-6 font-semibold text-[1rem] cursor-pointer">Hemen Başvur!</button>
      </nav>
    </div>
  </header>
  )
}

/*kontrol edildi */
